import { NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, map, mergeMap, of } from 'rxjs';
import { McDonaldsEvent } from '../../../data/mcdonalds-event';
import { Store } from '../../../data/store';
import { EventService } from '../../../services/event.service';
import { StoreService } from '../../../services/store.service';

export interface BookingCancellationFormContext<T extends McDonaldsEvent> {
  event: T; 
  store: Store; 
}

@Component({
    selector: 'mcdo-booking-cancellation-form',
    imports: [TranslateModule, FaIconComponent, NgTemplateOutlet],
    templateUrl: './cancellation-form.component.html',
    styleUrl: './cancellation-form.component.css'
})
export class BookingCancellationFormComponent<T extends McDonaldsEvent> implements OnInit {

  isCancelled: boolean;

  @Input({ required: true })
  templateEventInfo: TemplateRef<any>;

  context: BookingCancellationFormContext<T>;

  constructor(
    private eventsService: EventService<T>,
    private storeService: StoreService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show()
    this.route.queryParams.pipe(
      map(params => params["key"] as string),
      mergeMap(key => this.eventsService.getBooking(key)),
      mergeMap(event => {
        return forkJoin({
          event: of(event),
          store: this.storeService.getStore(event.storeIdentifier)
        })
      })
    ).subscribe(result => {
      this.translate.use(result.event.language.toLowerCase() + "-CH");
      this.isCancelled = result.event.deleted;
      this.context = result;
      this.spinner.hide();
    });
  }

  onCancelBtnClick() {
    this.eventsService.cancelFrontOffice(this.context.event.link).subscribe(() => this.isCancelled = true);
  }

}
