import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { EChartsOption } from 'echarts';
import { get } from 'lodash-es';
import { NgxEchartsDirective, provideEchartsCore } from 'ngx-echarts';
import { Observable, map } from 'rxjs';
import { BookingChannel } from '../../../data/booking-channel';
import { McDonaldsEvent } from '../../../data/mcdonalds-event';
import { Store } from '../../../data/store';
import { EventService } from '../../../services/event.service';
import { StoreService } from '../../../services/store.service';
import { RoleUtils } from '../../../utils/roleUtils';
import { InputDatepickerComponent } from '../../input-datepicker/input-datepicker.component';
import * as echarts from "echarts/core";

export enum DateType {
    event,
    booking
}

export class DataParams {
    dateType: DateType;
    startDate: Date;
    endDate: Date;
}

export class CsvMapping {
    label: string;
    property: string;
    valueToString?: (value: any, stores: Map<number, Store>) => string;
}

@Component({
    selector: 'mcdo-booking-dashboard',
    imports: [NgSelectModule, TranslateModule, InputDatepickerComponent, NgxEchartsDirective, ReactiveFormsModule],
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.css',
    providers: [provideEchartsCore({ echarts })]
})
export class BookingDashboardComponent<T extends McDonaldsEvent> implements OnInit{

  @Input({required: true})
  chartBuilders: Array<(events : any, params? : DataParams) => EChartsOption>;

  @Input({required: true})
  csvMappings: CsvMapping[];

  stores: Store[];
  charts: EChartsOption[];

  DateType = DateType;
  BookingChannel = BookingChannel;

  searchForm = this.formBuilder.group({
    stores: this.formBuilder.control<number[]>([]),
    dateType: this.formBuilder.control<DateType>(DateType.booking),
    startDate: this.formBuilder.control<Date>(new Date()),
    endDate: this.formBuilder.control<Date>(new Date()),
    bookingChannel: this.formBuilder.group({
      guest: this.formBuilder.control<boolean>(true),
      store: this.formBuilder.control<boolean>(true),
      teleperf: this.formBuilder.control<boolean>(true)
    })
  });

  constructor(
    private storeService : StoreService,
    private bookingService: EventService<T>,
    private formBuilder : FormBuilder
  ) {}

  ngOnInit(): void {
    this.storeService.getAll().subscribe(stores => this.stores = stores);
  }

  getBookings(): Observable<T[]> {
    let formVal = this.searchForm.value;
    let observable: Observable<T[]>;

    let searchStores = formVal.stores;

    if (searchStores.length === 0) {
      searchStores = this.stores.map(s => s.storeIdNumber);
    }

    if (formVal.dateType === DateType.event) {
        observable = this.bookingService.findWithMultipleStoresAndEventDateBetween(searchStores, formVal.startDate, formVal.endDate, false);
    } else {
        observable = this.bookingService.findWithMultipleStoresAndBookingDateBetween(searchStores, formVal.startDate, formVal.endDate, false);
    }

    observable.pipe(
      map(bookings => bookings.filter(booking => {
          switch(RoleUtils.roleToBookingChannel(booking.role)) {
            case BookingChannel.GUEST:
              return formVal.bookingChannel.guest;

            case BookingChannel.RESTAURANT:
              return formVal.bookingChannel.store;

            case BookingChannel.TELEPERFORMANCE:
              return formVal.bookingChannel.teleperf;

            default:
              return true;
          }
        }))
    )

    return observable;
  }

  generateCsv() {
    this.getBookings().subscribe(bookings => {
      const columnCount = this.csvMappings.length;
      const storeMap = new Map(this.stores.map(store => [store.storeIdNumber, store]));
      let csv = this.csvMappings.map(x => `"${x.label}"`).join(";") + "\n";

      for (const booking of bookings) {
       for (let i = 0; i < columnCount; i++) {
        const mapping = this.csvMappings[i];
        const prop = get(booking, mapping.property);

        if (mapping.valueToString) {
          csv += `"${mapping.valueToString(prop, storeMap)}"`;
        } else {
          csv += `"${this.defaultValueToString(prop)}"`;
        }

        if (i !== columnCount - 1) {
          csv += ";";
        }
       }
       csv += "\n";
      }

      const blob = new Blob([csv], {type: "text/csv"});
      const blobUrl = window.URL.createObjectURL(blob);
      window.open(blobUrl);
      window.URL.revokeObjectURL(blobUrl);
    });
  }

  private defaultValueToString(prop: any) {
    switch (typeof prop) {
      case "boolean":
        return prop ? "Y" : "N";

      case "object":
        if (prop instanceof Date) {
          return prop.toISOString().split("T")[0];
        } else {
          return prop
        }

      default:
        return prop ? prop : "";
    }
  }

  generateDiagramms() {
    this.getBookings().subscribe(bookings => {
        let newCharts = [];

        if (bookings.length !== 0) {
          for (const builder of this.chartBuilders) {
            newCharts.push(builder(bookings, {
              dateType: this.searchForm.value.dateType,
              startDate: new Date(this.searchForm.value.startDate),
              endDate: new Date(this.searchForm.value.endDate)
            }));
          }
        }

        this.charts = newCharts;
    });
  }
}

