import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { map, Subject } from 'rxjs';
import { AvailableBookingSlot } from '../../../data/availableBookingSlot';
import { Contact } from '../../../data/contact';
import { Store } from '../../../data/store';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ContactInfoComponent } from "./contact-info/contact-info.component";
import { StoreAndSlotSelectComponent } from "./store-and-slot-select/store-and-slot-select.component";
import { ValidateComponent } from "./validate/validate.component";

@Component({
    selector: 'mcdo-booking-form',
    imports: [
        MatStepperModule, AsyncPipe, StoreAndSlotSelectComponent, ContactInfoComponent, ValidateComponent, CommonModule,
        TranslateModule, ConfirmationComponent
    ],
    templateUrl: './booking-form.component.html',
    styleUrl: './booking-form.component.css'
})
export class McdoBookingFormComponent<TSlot extends AvailableBookingSlot> implements OnInit {

  @Input({ required: true })
  stores: Store[];

  @Input({ required: true })
  secondStepLabelTemplate: TemplateRef<void>;

  @Input({ required: true })
  secondStepCompleteSubject: Subject<boolean>;

  @Input()
  storeTooltipExtendedTemplate: TemplateRef<any>;

  @Input({ required: true })
  eventRecapTemplate: TemplateRef<void>;

  @Input()
  contactAddressRequired: boolean;

  @Input({required: true})
  isConfirmed: boolean;
  
  @Input({required: true})
  confirmationImage: string;

  @Output()
  onStoreSelected = new EventEmitter<Store>();

  private _selectedStore: Store;

  set selectedStore(store: Store) {
    this._selectedStore = store;
    this.onStoreSelected.emit(this._selectedStore);
  }

  get selectedStore() {
    return this._selectedStore;
  }

  @Output()
  onSlotSelected = new EventEmitter<TSlot>();

  private _selectedSlot: TSlot;

  set selectedSlot(slot: TSlot) {
    this._selectedSlot = slot;
    this.onSlotSelected.emit(this._selectedSlot);
  }

  get selectedSlot() {
    return this._selectedSlot;
  }

  @Output()
  onContactSet = new EventEmitter<Contact>();

  @Output()
  onConfirm = new EventEmitter<void>();

  @ViewChild('stepper')
  private stepper: MatStepper;

  contact: Contact;

  isMobile = this.breakpointObserver
    .observe('(max-width: 577px)')
    .pipe(map(breakPoint => breakPoint.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.secondStepCompleteSubject.subscribe((isValid) => {
      this.stepper.selected.completed = isValid;
      if (isValid)
        this.stepper.next();
    });
  }

  onContactSubmit(contact: Contact) {
    this.contact = contact;
    this.onContactSet.emit(this.contact);
    this.stepper.selected.completed = true;
    this.stepper.next();
  }
}
