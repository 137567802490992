<div class="row">
    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="col-md-8 mx-auto">
        <div class="row">
            <div class="col-sm-6">
                <label for="inputFirstName" class="form-label">{{ "booking-form.contact.firstname" | translate }}</label>
                <input type="text" class="form-control" id="inputFirstName" formControlName="firstName">
                @if((isSubmitted || contactForm.controls.firstName.touched) && contactForm.controls.firstName.invalid) {
                    @if (contactForm.controls.firstName.hasError("required")) {
                        <div class="text-danger">{{ "booking-form.contact.fieldRequired" | translate }}</div>
                    }
                }
            </div>
            <div class="col-sm-6">
                <label for="inputLastName" class="form-label">{{ "booking-form.contact.lastname" | translate }}</label>
                <input type="text" class="form-control" id="inputLastName" formControlName="lastName">
                @if((isSubmitted || contactForm.controls.lastName.touched) && contactForm.controls.lastName.invalid) {
                    @if (contactForm.controls.lastName.hasError("required")) {
                        <div class="text-danger">{{ "booking-form.contact.fieldRequired" | translate }}</div>
                    }
                }
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <label for="inputPhone" class="form-label">{{ "global.labels.phoneNumber" | translate }}</label>
                <input type="tel" class="form-control" id="inputPhone" formControlName="phone">
                @if((isSubmitted || contactForm.controls.phone.touched) && contactForm.controls.phone.invalid) {
                    @if (contactForm.controls.phone.hasError("required")) {
                        <div class="text-danger">{{ "booking-form.contact.fieldRequired" | translate }}</div>
                    }
                    @if (contactForm.controls.phone.hasError("invalidPhoneNumber")) {
                        <div class="text-danger">{{ "booking-form.contact.invalidPhoneNumber" | translate }}</div>
                    }
                }
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <label for="inputEmail" class="form-label">{{ "global.labels.email" | translate }}</label>
                <input type="email" class="form-control" id="inputEmail" formControlName="email">
                @if((isSubmitted || contactForm.controls.email.touched) && contactForm.controls.email.invalid) {
                    @if (contactForm.controls.email.hasError("required")) {
                        <div class="text-danger">{{ "booking-form.contact.fieldRequired" | translate }}</div>
                    }
                }
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <label for="inputConfirmEmail" class="form-label">{{ "booking-form.contact.email-confim" | translate }}</label>
                <input type="email" class="form-control" id="inputConfirmEmail" formControlName="emailConfirm">
                @if((isSubmitted || contactForm.controls.emailConfirm.touched)) {
                    @if (contactForm.controls.emailConfirm.invalid && contactForm.controls.emailConfirm.hasError("required")) {
                        <div class="text-danger">{{ "booking-form.contact.fieldRequired" | translate }}</div>
                    }
                    @if (contactForm.hasError("emailNotConfirmed")) {
                        <div class="text-danger">{{ "booking-form.contact.emailNotConfirmed" | translate }}</div>
                    }
                }
            </div>
        </div>

        @if(addressRequired) {
            <div formGroupName="address">
                <div class="row">
                    <div class="col-sm-6">
                        <label for="inputStreet" class="form-label">{{ "booking-form.contact.street" | translate }}</label>
                        <input type="text" class="form-control" formControlName="street" id="inputStreet">
                        @if((isSubmitted || contactForm.controls.address.controls.street.touched) && contactForm.controls.address.controls.street.invalid) {
                            @if (contactForm.controls.address.controls.street.hasError("required")) {
                                <div class="text-danger">{{ "booking-form.contact.fieldRequired" | translate }}</div>
                            }
                        }
                    </div>
                    <div class="col-sm-6">
                        <label for="inputStreetNum" class="form-label">{{ "booking-form.contact.street-number" | translate }}</label>
                        <input type="text" class="form-control" formControlName="streetNum" id="inputStreetNum">
                        @if((isSubmitted || contactForm.controls.address.controls.streetNum.touched) && contactForm.controls.address.controls.streetNum.invalid) {
                            @if (contactForm.controls.address.controls.streetNum.hasError("required")) {
                                <div class="text-danger">{{ "booking-form.contact.fieldRequired" | translate }}</div>
                            }
                        }
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label for="inputZipCode" class="form-label">{{ "booking-form.contact.zipCode" | translate }}</label>
                        <input type="text" class="form-control" formControlName="zipCode" id="inputZipCode">
                        @if((isSubmitted || contactForm.controls.address.controls.zipCode.touched) && contactForm.controls.address.controls.zipCode.invalid) {
                            @if (contactForm.controls.address.controls.zipCode.hasError("required")) {
                                <div class="text-danger">{{ "booking-form.contact.fieldRequired" | translate }}</div>
                            }
                        }
                    </div>
                    <div class="col-sm-6">
                        <label for="inputCity" class="form-label">{{ "booking-form.contact.city" | translate }}</label>
                        <input type="text" class="form-control" formControlName="city" id="inputCity">
                        @if((isSubmitted || contactForm.controls.address.controls.city.touched) && contactForm.controls.address.controls.city.invalid) {
                            @if (contactForm.controls.address.controls.city.hasError("required")) {
                                <div class="text-danger">{{ "booking-form.contact.fieldRequired" | translate }}</div>
                            }
                        }
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <label for="inputCountry" class="form-label">{{ "booking-form.contact.country" | translate }}</label>
                        <input type="text" class="form-control" formControlName="country" id="inputCountry">
                        @if((isSubmitted || contactForm.controls.address.controls.country) && contactForm.controls.address.controls.country.invalid) {
                            @if (contactForm.controls.address.controls.country.hasError("required")) {
                                <div class="text-danger">{{ "booking-form.contact.fieldRequired" | translate }}</div>
                            }
                        }
                    </div>
                </div>
            </div>
        }
        <button class="btn btn-secondary mt-3" type="submit">{{"global.button.validate" | translate}}</button>
    </form>
</div>