import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, mergeMap, toArray } from 'rxjs';
import { Store } from '../../data/store';
import { StoreService } from '../../services/store.service';

@Component({
    selector: 'mcdo-store-select',
    imports: [TranslateModule, NgSelectModule, FormsModule],
    templateUrl: './store-select.component.html',
    styleUrl: './store-select.component.css',
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StoreSelectComponent), multi: true }
    ]
})
export class StoreSelectComponent implements OnInit, ControlValueAccessor {
  @Output()
  onStoreSelected = new EventEmitter<Store>();

  @Input()
  multiple : boolean

  stores: Store[];
  selectedStore: Store;
  isReadOnly: boolean;

  private onChange = (_: Store) => {};
  private onTouched = () => {};

  constructor(
    public translate: TranslateService,
    private storeService: StoreService,
    private security: OidcSecurityService
  ) { }

  ngOnInit(): void {
    this.onStoreSelected.subscribe(x => this.onChange(x));

    this.security.getUserData().pipe(
      mergeMap(userData => {
        const userStores = userData.stores;
        if (userStores.length !== 1)
          return this.storeService.getAll()
            .pipe(map(stores => {
              if (userStores.length === 0)
                return stores;

              return stores.filter(store => userStores.includes(store.storeIdNumber));
            }))
        else
          return this.storeService.getStore(userData.stores[0])
            .pipe(toArray())
      }),
    ).subscribe(stores => {
      this.stores = stores

      if (this.stores.length === 1) {
        this.selectedStore = this.stores[0];
        this.onStoreSelected.emit(this.selectedStore);
        this.isReadOnly = true;
      }
    });

    this.stores = [];
  }

  writeValue(obj: Store): void {
    this.onTouched();
    this.selectedStore = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isReadOnly = isDisabled;
  }

}
